<template>
  <a-row :gutter="25">
    <div class="card w-100">
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            name="sDash_validation-form"
            ref="formRef"
            :model="registrationForm"
            :rules="rules"
            :layout="formState.layout"
          >
            <a-row :gutter="30">
              <a-col :md="11" :sm="24" :xs="24" 
                v-if="!eventForm.admin_can_register || eventForm.citizen_can_register || eventForm.edu_can_register"
              >
                <p class="title-normal m-0">¿Solo el Director de CT puede registrar? <span v-if="!eventForm.citizen_can_register">*</span></p>
                <a-form-item
                  ref="director_register" 
                  :name="eventForm.citizen_can_register ? '' : 'director_register'"
                  class="form-font pt-0 mx-3">
                  <a-radio-group v-model:value="registrationForm.director_register" >
                    <a-radio class="form-font" :value="1"> Si </a-radio>
                    <a-radio class="form-font" :value="0"> No </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col 
                :md="13" :sm="24" :xs="24" 
                v-if="!(eventForm.edu_can_register && eventForm.student_can_register)
                      && !eventForm.student_can_register
                      || eventForm.citizen_can_register"
              >
                <p class="title-normal m-0">¿Solo el Administrador de Área puede registrar? <span v-if="!eventForm.citizen_can_register">*</span></p>
                <a-form-item
                  ref="admin_area_register"
                  :name="eventForm.citizen_can_register ? '' : 'admin_area_register'"
                  class="form-font pt-0 mx-3"
                >
                  <a-radio-group v-model:value="registrationForm.only_admin_register" >
                    <a-radio class="form-font" :value="1"> Si </a-radio>
                    <a-radio class="form-font" :value="0"> No </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
            <div v-if="formState.roles.find((rol) =>
                  removeAccents(rol.nombre).toLowerCase() == removeAccents(AREA_ADMIN).toLowerCase() ||
                  rol.nombre == 'Administrador general'
                )"
            >
              <a-row :gutter="30">
                <a-col :md="24" :xs="24">
                  <a-form-item ref="responsible_id" name="responsible_id" label="Responsable de evento *" class="title-normal">
                    <a-select
                      size="large"
                      name="responsible_id"
                      class="sDash_fullwidth-select title-normal"
                      v-model:value="registrationForm.responsible_id"
                      @change="getResponsibleEventData()"
                      show-search
                      :optionFilterProp="'children'"
                      >
                      <a-select-option class="title-normal" key="opcion_1" value="">Seleccione una opción</a-select-option>
                      <a-select-option
                        v-for="responsibleEvent in formState.responsibleEventUser"
                        class="title-normal" 
                        :key="responsibleEvent"
                        :value="responsibleEvent.user_id">
                        {{ responsibleEvent.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="12" :xs="24">
                  <a-form-item ref="email" name="email" label="Correo *" class="title-normal">
                    <a-input v-model:value="registrationForm.email" class="input-field" disabled></a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :xs="24">
                  <a-form-item ref="telephone" name="telephone" label="Teléfono" class="title-normal">
                    <a-input v-model:value="registrationForm.telephone" class="input-field" v-on:keypress="isNumber($event)"></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <a-row :gutter="30">
              <a-col :md="24" :xs="24">
                <p class="title-normal m-0">¿Deseas que los datos de contacto del Responsable sean visibles? *</p>
                <a-form-item ref="info_visible" name="info_visible" class="form-font pt-0 mx-3">
                  <a-radio-group v-model:value="registrationForm.info_visible" >
                    <a-radio class="form-font" :value="1"> Si </a-radio>
                    <a-radio class="form-font" :value="0"> No </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" 
              v-if="formState.roles.find((rol) => 
                rol.nombre == 'Responsable de evento' ||
                removeAccents(rol.nombre).toLowerCase() == removeAccents(AREA_ADMIN).toLowerCase() ||
                rol.nombre == 'Administrador general'
              )">
              <a-col :md="24" :xs="24">
                <p class="title-normal m-0">Responsable de registro *</p>
                <a-form-item ref="attendance_record_responsible_id" name="attendance_record_responsible_id" class="form-font pt-0 mx-3">
                  <a-select
                    size="large"
                    name="attendance_record_responsible_id"
                    class="sDash_fullwidth-select title-normal"
                    v-model:value="registrationForm.attendance_record_responsible_id"
                    @change="getResponsibleRegisterData($event)"
                    show-search
                    :optionFilterProp="'children'"
                    >
                    <a-select-option class="title-normal" key="opcion_1" value="">Seleccione una opción</a-select-option>
                    <a-select-option class="title-normal" 
                      v-for="responsible in formState.responsibleRecordsUser"
                      :key="responsible"
                      :value="responsible.user_id">
                      {{ responsible.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </div>
  </a-row>
</template>

<script>
import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';
import { ref, reactive } from 'vue';
import { getItem } from '../../../../utility/localStorageControl';
import { isNumber, removeAccents } from '@/utility/utility';
import { getResponsibleRecordsUser, getAdministrativeAreas, responsibleEventUser } from '@/services/events';
import {GENERAL_ADMIN, EVENT_MANAGER, AREA_ADMIN, PARTICIPANT, CCT_DIRECTOR} from '@/constants/roles';

const CTRegistrationView = {
  name: 'CTRegistrationView',
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
  },
  props: {
    registrationForm: Object,
    eventForm: Object,
    sendForm: null,
    administrativeAreaIds: Array
  },
  watch: {
    sendForm: function () {
      this.submit();
    },
    //functionality if admin areas changed, but its not required yet
    administrativeAreaIds: function() {
      this.changeResponsibleEventUserData()
    }
  },
  emits: ['getFormValues'],
  async setup(props, {emit}) {
    const formRef = ref();

    const getAdministrativeAreasFunc = async () => {
      var areas = [];
      await getAdministrativeAreas().then((response) => {
        if(response.success){
          areas = response.data;
        }
      })
      .catch(() => {
        //console.log(error)
      })
      return areas;
    }

    const getResponsibleRecordUser = async () => {
      let responsibleRecordsUser = [];
      const roles = getItem("roles");
      const hasPermition = roles.find((rol) => {
          return rol.nombre == 'Responsable de evento' ||
            removeAccents(rol.nombre).toLowerCase() == removeAccents(AREA_ADMIN).toLowerCase() ||
            rol.nombre == 'Administrador general'
        }
      );
      if(hasPermition){
        await getResponsibleRecordsUser().then((response) => {
          if(response.success){
            responsibleRecordsUser = JSON.parse(JSON.stringify(response.record_responsible_users));
          }
        }).catch(() => {

        })
      }
      return responsibleRecordsUser;
    }

    const getAllResponsibleEvent = async () => {
      let responsibleEventUsers = [];
      let data = {
        ids: null
      }
      if(props.administrativeAreaIds.length){
        data.ids = props.administrativeAreaIds;
        responsibleEventUsers = getResponsibleEventUsers(data);
      } else {
        const areas = await getAdministrativeAreasFunc();
        const ids = getAllAdministrativeAreasIds(areas);
        data.ids = ids;
        responsibleEventUsers = getResponsibleEventUsers(data);
      }
      return responsibleEventUsers;
    }

    const getResponsibleEventUsers = async (data) => {
      let responsibles = [];
      await responsibleEventUser(data).then((response) => {
        if(response.success){
          responsibles = JSON.parse(JSON.stringify(response.users));
        }
      }).catch((error) => {
        console.log(error)
      })
      return responsibles;
    }

    const getAllAdministrativeAreasIds = (areas) => {
      let ids = [];
      areas.forEach((element) => {
        ids.push(element.id_arbol);
      })
      return ids;
    }

    const formState = reactive({
      id: props.index,
      layout: 'vertical',
      roles: getItem("roles"),
      responsibleEventUser: await getAllResponsibleEvent(),
      responsibleRecordsUser: await getResponsibleRecordUser(),
    });
    formState.roles[0].nombre =  formState.roles[0].nombre.normalize('NFD')
     .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
     .normalize();

    //functionality if admin areas changed, but its not required yet
    const changeResponsibleEventUserData = async () => {
      /*props.registrationForm.responsible_id = "";
      props.registrationForm.email = "";
      props.registrationForm.telephone = "";
      formState.responsibleEventUser = await getAllResponsibleEvent();*/
    }

    const getResponsibleEventData = () => {
      let email = "";
      const option = formState.responsibleEventUser.find((opt) => opt.user_id == props.registrationForm.responsible_id);
      if (option) {
        email = option.email;
      }
      props.registrationForm.email = email;
      /*props.registrationForm.email = "pruebas.mimuro2@jaliscoedu.mx"
      props.registrationForm.telephone = "1234567890"*/
    }

    const rules = {
      responsible_id: [
        {
          required: true,
          message: "Este campo es requerido",
          trigger: 'blur',
          type: 'number',
        },
      ],
      only_admin_register: [
        {
          required: true,
          message: "Este campo es requerido",
          trigger: 'blur',
          type: 'number',
        },
      ],
      email: [
        {
          required: true,
          message: "Este campo es requerido del tipo email",
          trigger: "blur",
          type: "email"
        }
      ],
      telephone: [
        {
          required: false,
          message: "Este campo es requerido",
          trigger: "blur",
          type: "string"
        },
        {
          min: 10,
          max: 10,
          message: 'Teléfono no válido, deben ser 10 caracteres exactos',
          trigger: 'blur',
        },
      ],
      attendance_record_responsible_id: [
         {
          required: true,
          message: "Este campo es requerido",
          trigger: 'blur',
          type: 'number',
        },
      ]
    }

     const submit = () => {
      console.log("validando CT")
      formRef.value
        .validate()
        .then(() => {
          emit('getFormValues', { is_ok: true, typeForm: 'CTRegistration' });
        })
        .catch((e) => {
          console.log(e)
          emit('getFormValues', { is_ok: false, typeForm: 'CTRegistration' });
        });
    };

    const getResponsibleRegisterData = (value) => {
      props.registrationForm.attendance_record_responsible_id = value;
    }

    return {
      formState,
      rules,
      formRef,
      getResponsibleEventData,
      submit,
      getResponsibleRegisterData,
      isNumber,
      removeAccents,
      getAllAdministrativeAreasIds,
      changeResponsibleEventUserData,
      GENERAL_ADMIN, EVENT_MANAGER, AREA_ADMIN, PARTICIPANT, CCT_DIRECTOR
    };
  },
};
export default CTRegistrationView;
</script>

<style lang="sass">
.card
    background-color: #fff !important
    padding: 1rem
    border-top: 0.5px solid #bdbdbd

.form-font
    font-family: Nutmeg
    &.title
        font-weight: bold
        padding: 0

.input-field
  border-radius: 30px

.ant-select-selector
    border-radius: 20px !important
    padding-top: 5px !important
    padding-bottom: 5px !important
.ant-form-item-required::before
  content: '' !important
</style>